<template>
  <div class="con-box">
    <el-table :data="cashData" style="width: 100%"  ref="multipleTable"
      @selection-change="handleSelectionChange"
        :row-style="{height:35+'px'}"
      :header-cell-style="{ textAlign: 'center' }"
      :cell-style="{ textAlign: 'center'}">
        <el-table-column type="selection" width="50"></el-table-column >
      <el-table-column prop="created" :formatter="dateFormat" label="日期" min-width="110">
      </el-table-column>
      <el-table-column prop="username" label="用户名" min-width="110">
      </el-table-column>
      <el-table-column prop="paych" label="提现类型" :formatter="paychFormat">
      </el-table-column>
      <el-table-column prop="amount" label="提现数(泛函币)" min-width="130">
      </el-table-column>
      <el-table-column prop="tax" label="税额(泛函币)" min-width="100">
      </el-table-column>
      <el-table-column prop="exchange" label="汇率" width="50">
      </el-table-column>
        <el-table-column  label="备注" width="200">
          <template slot-scope="scope">
            <el-input  type="textarea" :rows="1" maxlength="40"  v-model.trim="scope.row.why" size="mini" placeholder="请输入备注"></el-input>
          </template>
      </el-table-column>
      <el-table-column label="操作" width="150" >
        <template slot-scope="scope">
              <el-button type="primary" size="mini" @click="gotoHitMoney(scope.$index, scope.row.username, cashData)">打款</el-button>
              <el-button type="danger" :disabled="scope.row.why == '' " size="mini" @click="refuseMoney(scope.$index, scope.row.username,scope.row.why, cashData)">拒绝</el-button>
        </template>
      </el-table-column>
    </el-table>
    <div class="cash-btn">
      <span class="mr-3 text-muted">共{{cashData.length}}条申请</span>
      <el-button type="primary" size="mini" @click="moreHitMoney">批量打款</el-button>
      <el-button type="danger" size="mini" @click="moreRefuseHitMoney">批量拒绝</el-button>
    </div>
  </div>
</template>
<script>
import { getSalaryList, reviewSalary, hitMoney } from '@/api/reviews'

export default {
  name: 'salarycheck',
  data () {
    return {
      cashData: [], // 表数据
      allList: [], // 选中的row
      moneyList: [], // 存了打钱信息直接发后端
      noMoneyList: [], // 存了不打钱的信息直接发后端
      myFlag: true
    }
  },
  mounted () {
    // this.$nextTick(function() {
    //   let vm = this
    //   getSalaryList().then(
    //     res => {
    //       vm.cashData = res.data.map(
    //         val => {
    //           val.status = '打款'
    //           return val
    //         }
    //       )
    //       vm.$message({
    //         message: '获取数据成功',
    //         type: 'success'
    //       })
    //     }
    //   ).catch(
    //     err => {
    //       console.log(err)
    //       vm.cashData = []
    //     }
    //   )
    // })
    this.init()
  },
  methods: {
    async init () {
      const res = await getSalaryList(status)
      this.cashData = res.data.items.map(item => {
        item.why = ''
        return item
      })
      // this.cashData = res.data.map() // console.log(this.cashData)
    },
    dateFormat (row) {
      const dataNew = new Date(parseInt(row.created) * 1000)
      const year = dataNew.getMonth() + 1
      return dataNew.getFullYear() + '-' + year + '-' + dataNew.getDate()
    },
    paychFormat ({ paych = 0 }) {
      if (paych === 1) {
        return '支付宝'
      }
      if (paych === 3) {
        return 'PayPal'
      }
      return '--'
    },
    gotoHitMoney (index, name, rows) {
      const data = []
      data.push({ username: name })
      // console.log(data, '发送给后台的数据')
      this.$confirm(name + '即将打款,请确认信息是否正确?', '打款确认', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        const res = await hitMoney(data)
        // console.log('输出结果1111:', res)
        if (res.code === 0 && res.data.failures.length < 1) {
          // code为0且没有fail信息为成功
          this.$message({
            type: 'success',
            message: name + '的提现申请已成功!'
          })
          rows.splice(index, 1)
        } else {
          // 失败收到了fail信息
          this.$message({
            type: 'error',
            message: res.data.failures[0].username + res.data.failures[0].reason
          })
          // why = data.failures[0].reason
          // console.log(index)
          // console.log(rows)
          this.cashData[index].why = res.data.failures[0].reason
          this.$refs.multipleTable.clearSelection()
        }
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消操作'
        })
      })
    },
    async refuseMoney (index, name, ress, rows) {
      const data = []
      data.push({ username: name, reason: ress })
      this.$confirm(name + '的申请被拒绝,请确认信息是否正确?', '打款确认', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        // console.log(data, '发给后台的数据')
        const res = await reviewSalary(data)
        // console.log(res)
        if (res.code === 0) {
          this.$message({
            type: 'error',
            message: name + '的提现申请已拒绝!'
          })
          rows.splice(index, 1)
        }
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消操作'
        })
      })
    },
    handleSelectionChange (row) {
      // console.log(row)
      this.allList = row
    },
    async moreHitMoney () {
      /** 批量打钱
       *  example 10人成功5人失败5人
       *  成功的5人移除 meaasge 5人成功
       *  失败的5人 message、、a、b、c、d、e失败 原因填入input
       */
      if (this.allList.length < 1) {
        console.log('没有选中')
        this.$message({
          type: 'error',
          message: '请选择你需要打款的用户'
        })
      } else {
        // console.log(this.allList, '选中的数据')
        const data = []
        this.allList.forEach((item) => {
          data.push({ username: item.username })
        })
        let str = ''
        data.forEach(item => { str += item.username + '、' })
        // console.log(data, '发给后台的数据')
        this.$confirm(str + '申请提现,请确认信息是否正确?', '打款确认', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(async () => {
          const res = await hitMoney(data)
          // console.log(res, '后台的返回结果')
          if (res.code === 0) {
            // 批量打钱成功的
            // 发的数量-返回的数量=成功的数量、、、、xx人成功 0人不展示成功失败展示abcd失败
            const temp = data.length - res.data.failures.length // 成功的人数
            // const temp = 1//测试用
            if (temp > 0) {
              this.$message({
                type: 'success',
                message: temp + '个人打款成功!'
              })
              // 拿到成功的那些人的名字取列表中过滤掉(发的-失败的=成功的)
              //  console.log('%c%s', 'color:purple', '输出结果 :', data, '一共给后台发去的人数')
              //  console.log('%c%s', 'color:purple', '输出结果 :', res.data.failures, '后台告诉我失败的人')
              let arr = data // 通过筛选得到了成功的人、、去tablelist过滤掉
              res.data.failures.forEach((item) => {
                arr = arr.filter(e => e.username !== item.username)
              })
              // console.log('%c%s', 'color:purple', '输出结果 :', arr)
              // arr1=[{username: 'angey'}]
              arr.forEach(item => {
                this.cashData = this.cashData.filter(e => e.username !== item.username)
              })
            }
            // console.log('发的数量', data.length)
            // console.log('返回', res.data.failures.length)
            // console.log('succss & fail')
            // 批量打钱未成功的展示原因字段reason
            // 如果有返回的failures才做的计算、有失败的人
            let str = ''
            if (res.data.failures.length > 0) {
              res.data.failures.forEach((item) => {
                str += item.username + '、'
                const temp = this.cashData.find((e) => e.username === item.username)
                // console.log(temp, '找到的数据')
                // console.log(str, '找到的数据')
                temp.why = item.reason
              })
              this.$message({
                type: 'error',
                offset: 100,
                message: str + '打款失败!'
              })
            }
            this.$refs.multipleTable.clearSelection()
          }
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消操作'
          })
        })
      }
    },
    moreRefuseHitMoney () {
      // 批量拒绝打钱和上面区别什么时候收集到原因,填写原因后收集到
      if (this.allList.length < 1) {
        // console.log('没有选中')
        this.$message({
          type: 'error',
          message: '请选择你需要打款的用户'
        })
      } else {
        // 进入第二部、、、1是看有无勾选、、、2是看每个textarea是否都填写好了
        const temp = this.allList.map(item => this.cashData.find(it => it.username === item.username))
        const flag = temp.every(item => {
          return item.why !== ''
        })
        // console.log('%c%s', 'color:purple', '输出结果 :', flag)
        if (flag) {
          const data = []
          this.allList.forEach(item => {
            data.push({ username: item.username, reason: item.why })
          })
          let str = ''
          data.forEach(item => { str += item.username + '、' })
          this.$confirm(str + '的申请被拒绝,请确认信息是否正确?', '打款确认', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(async () => {
            const res = await reviewSalary(data)
            console.log(res, '看一下后台的返回结果')
            if (res.code === 0) {
              this.$message({
                type: 'success',
                message: `${str}拒绝打款成功！`
              })
              // console.log(data)
              // console.log(this.cashData)
              // 走接口原因会消失本地删除拒绝的用户
              data.forEach(item => {
                this.cashData = this.cashData.filter(e => e.username !== item.username)
              })
            }
            // const res = await reviewSalary(data)
            // 成功了去this.cashData过滤掉这几项data(拒绝的)
          }).catch(() => {
            this.$message({
              type: 'info',
              message: '已取消操作'
            })
          })
        } else {
          this.$message({
            type: 'error',
            message: '请填写备注'
          })
        }
      }
    }
  }
}
</script>

<style scope>

.con-box {
  padding-top: 50px;
}

.cash-btn {
  position: absolute;
  bottom: 50px;
  right: 50px;
}

.onBottom {
  position: fixed;
  width: 100%;
  bottom: 0;
}
/* .el-table{
  min-height: 500px;
} */
.el-table td{
  padding: 3px 0;
}
.el-checkbox__input.is-focus .el-checkbox__inner{
  border-color: #dcdfe6;
}
</style>
